import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { iconWa, coatingTopcoat, carTopcoat1, carTopcoat3, users } from '../../../assets';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import './user_home.scss'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { RiStarFill } from "react-icons/ri";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import TagManager from 'react-gtm-module'
import { FaShieldAlt, FaCloudSun, FaHandsWash } from "react-icons/fa";
import { GiStarsStack } from "react-icons/gi";
import { GiTripleScratches } from "react-icons/gi";
import { IoWaterSharp } from "react-icons/io5";

const tagManagerArgs = { 
  gtmId: 'G-WND7X8F40B' 
}
TagManager.initialize(tagManagerArgs)

const iframeCode = '<iframe src="https://widget.taggbox.com/150987" style="width:100%;height:600px;border:none;"></iframe>';


const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};

const FIRST_IMAGE = {
    imageUrl: "/static/media/car-after.85923fd37424e2f76fa7.jpg"
};
const SECOND_IMAGE = {
    imageUrl: "/static/media/car-before.730bedd2182f525bd804.jpg"
};

const items = [
    <div className="content-testi" data-value="1">
        <div className='content-left'>
            <img className="img-profile" src={users} alt="First slide"/>
        </div>
        <div className='content-right'>
            <p className='content-testi-name'>Windayana Putri Sitorus</p>
            <p className='content-testi-star'><RiStarFill/><RiStarFill/><RiStarFill/><RiStarFill/><RiStarFill/></p>
        </div>
        <p className='content-testi-text'>"Hasil Pekerjaannya bagus banget, Karyawannya ramah, dan ada ruang tunggu yang nyaman. Jadi Rekomendasi buat kita kalau mau coating Nano Ceramic di sini aja di Topcoat Medan Ringroad"</p>
    </div>,
    <div className="content-testi" data-value="2">
        <div className='content-left'>
            <img className="img-profile" src={users} alt="First slide"/>
        </div>
        <div className='content-right'>
            <p className='content-testi-name'>Rian Putra</p>
            <p className='content-testi-star'><RiStarFill/><RiStarFill/><RiStarFill/><RiStarFill/><RiStarFill/></p>
        </div>
        <p className='content-testi-text'>"Hasilnya sangat memuaskan sesuai yang saya harapkan,  pekerja nya sangat ramah. setelah di coating di Topcoat Medan Ringroad mobil saya terlihat seperti baru lagi dan lebih mengkilap"</p>
    </div>,
    <div className="content-testi" data-value="3">
        <div className='content-left'>
            <img className="img-profile" src={users} alt="First slide"/>
        </div>
        <div className='content-right'>
            <p className='content-testi-name'>Agus Surianto</p>
            <p className='content-testi-star'><RiStarFill/><RiStarFill/><RiStarFill/><RiStarFill/><RiStarFill/></p>
        </div>
        <p className='content-testi-text'>"Saya merasa puas, karena Hasilnya bagus sesui dengan yang diharapkan. Semoga Topcoat makin sukses. Terimakasih"</p>
    </div>,
    <div className="content-testi" data-value="4">
        <div className='content-left'>
            <img className="img-profile" src={users} alt="First slide"/>
        </div>
        <div className='content-right'>
            <p className='content-testi-name'>Irwan Fahrudan</p>
            <p className='content-testi-star'><RiStarFill/><RiStarFill/><RiStarFill/><RiStarFill/><RiStarFill/></p>
        </div>
        <p className='content-testi-text'>"Saya puas sekali menggunakan Nano Ceramic dari Topcoat karena selain banyak manfaat yang saya dapatkan, memberikan garansi hingga 2 tahun. recommended sekali!!"</p>
    </div>,
    <div className="content-testi" data-value="1">
        <div className='content-left'>
            <img className="img-profile" src={users} alt="First slide"/>
        </div>
        <div className='content-right'>
            <p className='content-testi-name'>Hirfan Arifin</p>
            <p className='content-testi-star'><RiStarFill/><RiStarFill/><RiStarFill/><RiStarFill/><RiStarFill/></p>
        </div>
        <p className='content-testi-text'>"Saya sudah mampir di sini pelayanan sangat bagus dan ramah. Untuk hasilnya juga sangat memuaskan dan untuk jasanya sangat terjangkau."</p>
    </div>,
];

const Home = () => {
    return (
        <>  
            <div className="content-wrapper-brand-img">
                <div className="box-container box-container-img">  
                    <div className='box-b-txt'>
                        <p className='box-container-txt-sub'>SAPPHIRE AND QUART SERUM</p>
                        <p className='box-container-txt'>NANO CERAMIC COATING</p>
                        <p className='box-container-txt-sub-sub'>Dapatkan pelapisan mobil terbaik untuk perlindungan optimal dan kilau yang menakjubkan. Lawan goresan, sinar UV, dan penuaan kendaraan Anda. Ayo buat mobil Anda bersinar seperti baru!</p>
                        <a href="https://bit.ly/topcoat-medan"><center><Button className='btn-container margin-20' size="lg" active>Booking Sekarang</Button></center></a>
                    </div>
                </div>
            </div>
            {/* <div className='box-menu'>
                <div className='content-wrapper-menu'>
                    <Container> 
                        <Row>
                            <Col md>
                                <div className='box-img-menu'>
                                    <img className="img-menu" src="https://scuto.co.id/wp-content/uploads/2023/01/Page-Image-Nano-Ceramic.jpg" alt="First slide"/>
                                </div>            
                            </Col>
                            <Col md>
                                <div className='box-img-menu'>
                                    <img className="img-menu" src="https://scuto.co.id/wp-content/uploads/2023/02/Page-Image-Karpet-Comfort.jpg" alt="First slide"/>
                                </div>            
                            </Col>
                            <Col md>
                                <div className='box-img-menu'>
                                    <img className="img-menu" src="https://scuto.co.id/wp-content/uploads/2023/01/Page-Image-Glass-Coating.jpg" alt="First slide"/>
                                </div>          
                            </Col>
                        </Row>
                    </Container>   
                </div>
            </div> */}
            <div className="icon-whatsapp">
                <a href="https://bit.ly/topcoat-medan">
                    <img className="img-menu" src={iconWa} alt="First slide"/>      
                </a>   
            </div>
            <div className='content-wrapper m-top-bottom-25'>
                <div className='content'>
                    <Container> 
                        <Row>
                            <Col md={4}>
                                <div className='content-box'>
                                    <img className="content-img-product" src={coatingTopcoat} alt="First slide"/>
                                </div>            
                            </Col>
                            <Col md={8}>
                                <div className='content-box'>
                                    <p className='main-text'>QUARTZ | SAPPHIRE</p>
                                    <p className='heading-title red'>PILIHAN TERBAIK UNTUK</p>
                                    <p className='heading-title size-main'>COATING MOBIL ANDA</p>
                                    <p className='main-text'>Laminating Mobil Nano Ceramic adalah paint protection system yang berguna untuk melindungi cat mobil Anda serta memberikan garansi Nasional hingga 3 Tahun. Selain mencegah dari debu, kotoran, jamur, polusi dan hujan asam. Dengan menggunakan nano Ceramic, cat mobil Anda kedap udara sehingga tidak akan berubah warna, terlihat wetlook dan mudah dibersihkan.</p>
                                </div>        
                            </Col>
                        </Row>
                    </Container> 
                </div>
            </div>
                
            <div className='content gray-color'>
                <div className='content-wrapper m-top-bottom-25'>
                <div className='content-box'>
                    <p className='heading-title red center'>KENAPA MOBIL ANDA PERLU</p>
                    <p className='heading-title size-main center'>COATING NANO CERAMIC?</p>
                    <p className='heading-title-sub center'>Berikut ini adalah 6 manfaat setelah Anda melindungi exterior mobil Anda dengan lapisan nano ceramic dari Topcoat</p>
                
                    <div className='content-width'>
                        <Container> 
                            <Row>
                                <Col md={4} className='mrv-padding'>
                                    <div className='content-nano-left'>
                                        <div className='content-right-nano'>
                                            <p className='text-main-nano'>PERMANENT PROTECTION</p>
                                            <p className='text-nano'>Memberikan perlindungan lebih kuat dan daya tahan jauh lebih lama</p>
                                        </div>
                                        <div className='content-left-nano'><FaShieldAlt /></div>
                                    </div>
                                    <div className='content-nano-left'>
                                        <div className='content-left-nano'><FaCloudSun /></div>
                                        <div className='content-right-nano'>
                                            <p className='text-main-nano'>UV RESISTANT</p>
                                            <p className='text-nano'>Mencegah cat memudar dan teroksidasi akibat sinar UV</p>
                                        </div>
                                    </div>
                                    <div className='content-nano-left'>
                                        <div className='content-left-nano'><IoWaterSharp /></div>
                                        <div className='content-right-nano'>
                                            <p className='text-main-nano'>HYDROPHOBIC EFFECT</p>
                                            <p className='text-nano'>Membuat air tidak mau menempel pada exterior seperti air pada daun talas</p>
                                        </div>
                                    </div>       
                                </Col>
                                <Col md={4} className='mrv-padding add-padding'>
                                    <div className='img-car-nano-m'>
                                        <img className='img-car-nano' src={carTopcoat1}/>
                                    </div>
                                </Col>
                                <Col md={4} className='mrv-padding'>
                                    <div className='content-nano-right'>
                                        <div className='content-left-nano'><GiTripleScratches /></div>
                                        <div className='content-right-nano'>
                                            <p className='text-main-nano'>SCRATCH RESISTANT</p>
                                            <p className='text-nano'>Melindungi exterior dari baret halus atasu swirl mark</p>
                                        </div>
                                    </div>
                                    <div className='content-nano-right'>
                                        <div className='content-left-nano'><GiStarsStack /></div>
                                        <div className='content-right-nano'>
                                            <p className='text-main-nano'>HIGH GLOSS FINISH</p>
                                            <p className='text-nano'>Mobil mengkilap yang tahan lama</p>
                                        </div>
                                    </div>
                                    <div className='content-nano-right'>
                                        <div className='content-left-nano'><FaHandsWash /></div>
                                        <div className='content-right-nano'>
                                            <p className='text-main-nano'>EASY TO CLEAN</p>
                                            <p className='text-nano'>Exterior lebih mudah dibersihkan</p>
                                        </div>
                                    </div>       
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* <div className='content-box'>
                        <img className="content-img-product" src="https://scuto.co.id/wp-content/uploads/2023/01/Page-Gambar-Mobil-Manfaat-Coating-di-Scuto-2-1024x449.jpg"/>
                    </div> */}
                </div>
                </div>
            </div>

            <div className='content-wrapper m-top-bottom-25'>
                <div className='content'>
                    <div className='content-box'>
                        <p className='heading-title red center'>ILUSTRASI BODY KENDARAAN</p>
                        <p className='heading-title size-main center'>DENGAN TOPCOAT NANO CERAMIC</p>
                        <p className='heading-title-sub center'>Berikut ini adalah ilustrasi pada dasar cat mobil hingga clear coat</p>
                        
                        <img className="content-img-ilustrasi" src={carTopcoat3}/>
                       
                    </div>
                </div>
            </div>

            <div className="content-wrapper-brand-img">
                <div className='content-img-book'>
                    <p className='txt-book'>BOOKING SEKARANG</p>
                    <p className='txt-book-m'>BOOKING ONLINE JADI LEBIH MUDAH. TINGGAL KLIK DAN LANGSUNG DATANG SAJA</p>
                </div>  
            </div>

            <div className='content-wrapper m-top-bottom-25'>
                <div className='content'>
                    <div className='content-box'>
                        <p className='heading-title size-main center'>BEFORE & AFTER NANO CERAMIC</p>
                        <p className='heading-title-sub center'>Geser Untuk Melihat Perbedaan Mobil</p>
                        <p className='heading-title-sub center'>Sebelum dan Sesudah di Aplikasikan Nano Ceramic Coating</p>
                        <div className='content-box'>
                            <ReactBeforeSliderComponent
                                firstImage={FIRST_IMAGE}
                                secondImage={SECOND_IMAGE}
                            />
                            
                            {/* <img className="content-img-product" src="https://scuto.co.id/wp-content/uploads/2023/02/Illustrasi-Nano-Ceramic.png"/> */}
                        </div>
                    </div>
                </div>

                <div className='content'>
                    <div className='content-box'>
                        <p className='heading-title size-main center'>F.A.Q</p>
                        <p className='heading-title-sub center'>PERTANYAAN YANG SERING DITANYAKAN</p>
                        <div className='content-width'>
                            <Container> 
                                <Row>
                                    <Col md={6}>
                                        <div className='content-qa'>
                                            <p className='text-qa'>Apa Fungsi Topcoat Nano Ceramic?</p>
                                            <p className='text-qa-m'>Nano Ceramic Coating memiliki fungsi untuk menjaga tampilan mobil agar tetap mengkilap seperti baru. Lapisan nano coating ini memiliki kemampuan melindungi cat pada bodi mobil dari paparan polusi, goresan halus, oksidasi, dan juga memberikan efek kilau yang lebih tahan lama.</p>
                                        </div>         
                                    </Col>
                                    <Col md={6}>
                                        <div className='content-qa'>
                                            <p className='text-qa'>Apakah setelah Nano Ceramic masih bisa baret?</p>
                                            <p className='text-qa-m'>Tidak mencegah baret. Tapi mengurangi resiko goresan halus/swirl contohnya dari proses cuci mobil. Kenapa tidak anti baret? karena karakter nano ceramic itu sendiri yang lebih terfokus di mengubah karakter pernis menjadi lebih keras, walaupun terlihat baret, yang baret itu hanya di permukaan lapisan Nano Ceramic dan tidak sampai merusak permukaan pernis dari cat asli.</p>
                                        </div> 
                                    </Col>
                                    <Col md={6}>
                                        <div className='content-qa'>
                                            <p className='text-qa'>Apakah baret bisa hilang?</p>
                                            <p className='text-qa-m'>Baret bisa kita hilangkan saat proses paint correction (polishing). Namun tidak semua baret atau luka bisa dihilangkan. Kalau baret atau lukanya hanya merusak lapisan pernis dapat dihilangkan saat proses paint correction. Ciri-ciri baret yang sudah tidak bisa hilang yaitu kalau dipegang terasa cekung atau masuk ke dalam.</p>
                                        </div> 
                                    </Col>
                                    <Col md={6}>
                                        <div className='content-qa'>
                                            <p className='text-qa'>Apakah ada Garansi?</p>
                                            <p className='text-qa-m'>Anda akan mendapatkan garansi Topcoat hingga 3 tahun di seluruh cabang Topcoat yang berada di Indonesia. Garansi ini mencakup pengerjaan interior, exterior, dan ruang mesin.</p>
                                        </div>         
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>

                <div className='content'>
                    <div className='content-box'>
                        <p className='heading-title size-main center'>TESTIMONI</p>
                        <div className=''>
                            <AliceCarousel disableButtonsControls mouseTracking items={items} responsive={responsive} controlsStrategy="alternate"/>
                        </div>
                    </div>
                </div>
                
                <div className='content'>
                    <div className='content-box'>
                        <p className='heading-title size-main center'>Our Instagram</p>
                        <p className='heading-title-sub center'>Follow @topcoat_medan</p>

                        <div dangerouslySetInnerHTML={{ __html: iframeCode }} />
                        {/* <div class="elfsight-app-d9af5cf2-bdf2-49f6-82a5-bada7d8de0fe" data-elfsight-app-lazy></div>
                        <Helmet><script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script></Helmet>  */}
                    </div>    
                </div>
            </div>  
        </>
    )
}
export default Home
